import React from "react";
import SEO from "../components/seo"
import { Link } from "gatsby"
import "../components/global.scss"

import svgLogo from "../images/logo.svg";


const FormSuccess = () => {
    return (
        <>
            <SEO title="Form submitted" />
            <div className="form-submitted">
                <div className="card">
                    <div className="card-content">
                        <p className="title has-text-centered">
                            <img src={svgLogo} width="50%" alt="Taxibooker logo" />
                        </p>
                        <p className="subtitle has-text-centered mt-5 mb-2">
                            Thanks for getting in touch!<br /> We'll reply as soon as we can.
                        </p>
                    </div>
                    <footer className="card-footer">
                        <p className="card-footer-item">
                            <Link to="/" className="has-text-weight-bold text-brown">
                                GO BACK
                            </Link>
                        </p>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default FormSuccess